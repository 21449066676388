var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "no-click-animation": "",
        persistent: "",
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        "retain-focus": false,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on }) {
              return [_vm._t("activador", null, { on: on })]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c("the-nav-bar", {
            attrs: {
              disableFilter: _vm.loading,
              title: _vm.headerTitle,
              filter: _vm.filtro,
            },
            on: {
              filterInput: _vm.search,
              back: function ($event) {
                _vm.dialog = false
              },
            },
          }),
          _c(
            "v-container",
            { staticClass: "pa-5 pt-0" },
            [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center mt-10 pa-10" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "grey", size: "50" },
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-list",
                    [
                      _vm._t("options"),
                      !_vm.selectMultiplos
                        ? [
                            _vm._l(_vm.dadosFiltrados, function (dado, index) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: `${
                                      _vm.valueKey ? dado[_vm.valueKey] : dado
                                    }-${index}`,
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        staticClass: "pa-2",
                                        attrs: {
                                          "data-test-id": `dado-${index}`,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.select(dado)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass:
                                                  "primary--text subtitle-1",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.labelKey
                                                      ? dado[_vm.labelKey]
                                                      : dado
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-chevron-right"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    index + 1 < _vm.options.length
                                      ? _c("v-divider")
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            }),
                          ]
                        : [
                            _vm._l(_vm.dadosFiltrados, function (dado, index) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: `${
                                      _vm.valueKey ? dado[_vm.valueKey] : dado
                                    }-${index}`,
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "pa-2" },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass:
                                                  "primary--text subtitle-1",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.labelKey
                                                      ? dado[_vm.labelKey]
                                                      : dado
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-action", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.selects[
                                                    _vm.labelKey
                                                      ? dado[_vm.labelKey]
                                                      : dado
                                                  ],
                                                expression:
                                                  "selects[labelKey ? dado[labelKey] : dado]",
                                              },
                                            ],
                                            attrs: {
                                              "data-test-id": `dado-${index}`,
                                              type: "checkbox",
                                              id: _vm.labelKey
                                                ? dado[_vm.labelKey]
                                                : dado,
                                              "data-refids": dado[_vm.refKey],
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.selects[
                                                  _vm.labelKey
                                                    ? dado[_vm.labelKey]
                                                    : dado
                                                ]
                                              )
                                                ? _vm._i(
                                                    _vm.selects[
                                                      _vm.labelKey
                                                        ? dado[_vm.labelKey]
                                                        : dado
                                                    ],
                                                    null
                                                  ) > -1
                                                : _vm.selects[
                                                    _vm.labelKey
                                                      ? dado[_vm.labelKey]
                                                      : dado
                                                  ],
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a =
                                                      _vm.selects[
                                                        _vm.labelKey
                                                          ? dado[_vm.labelKey]
                                                          : dado
                                                      ],
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.selects,
                                                          _vm.labelKey
                                                            ? dado[_vm.labelKey]
                                                            : dado,
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.selects,
                                                          _vm.labelKey
                                                            ? dado[_vm.labelKey]
                                                            : dado,
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.selects,
                                                      _vm.labelKey
                                                        ? dado[_vm.labelKey]
                                                        : dado,
                                                      $$c
                                                    )
                                                  }
                                                },
                                                _vm.selectCheckBox,
                                              ],
                                            },
                                          }),
                                        ]),
                                      ],
                                      1
                                    ),
                                    index + 1 < _vm.options.length
                                      ? _c("v-divider")
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            }),
                            _c(
                              "v-btn",
                              {
                                staticClass: "text-none mt-5 mb-4",
                                attrs: {
                                  large: "",
                                  type: "submit",
                                  "data-test-id": "confirmar-dialogo-lista",
                                  elevation: "10",
                                  block: "",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.select({
                                      nome: _vm.selects,
                                      data: _vm.selectsTrue,
                                    })
                                  },
                                },
                              },
                              [_vm._v("Confirmar")]
                            ),
                          ],
                    ],
                    2
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }