<template>
  <v-dialog no-click-animation v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition" :retain-focus="false">
    <template v-slot:activator="{ on }">
      <slot name="activador" :on="on"></slot>
    </template>
    <v-card tile>
      <the-nav-bar :disableFilter="loading" :title="headerTitle" @filterInput="search" :filter="filtro" @back="dialog = false"></the-nav-bar>
      <v-container class="pa-5 pt-0">
        <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
          <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
        </div>
        <v-list v-else>
          <slot name="options"></slot>
          <template v-if="!selectMultiplos">
            <template v-for="(dado, index) in dadosFiltrados">
              <div :key="`${valueKey ? dado[valueKey] : dado}-${index}`">
                <v-list-item :data-test-id="`dado-${index}`" class="pa-2" @click="select(dado)">
                  <v-list-item-content>
                    <v-list-item-title class="primary--text subtitle-1">{{ labelKey ? dado[labelKey] : dado }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="index + 1 < options.length"></v-divider>
              </div>
            </template>
          </template>
          <template v-else>
            <template v-for="(dado, index) in dadosFiltrados">
              <div :key="`${valueKey ? dado[valueKey] : dado}-${index}`">
                <v-list-item class="pa-2">
                  <v-list-item-content>
                    <v-list-item-title class="primary--text subtitle-1">{{ labelKey ? dado[labelKey] : dado }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <input
                      :data-test-id="`dado-${index}`"
                      type="checkbox"
                      :id="labelKey ? dado[labelKey] : dado"
                      :data-refids="dado[refKey]"
                      @change="selectCheckBox"
                      v-model="selects[labelKey ? dado[labelKey] : dado]"
                    />
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="index + 1 < options.length"></v-divider>
              </div>
            </template>

            <v-btn
              large
              type="submit"
              :data-test-id="'confirmar-dialogo-lista'"
              elevation="10"
              block
              color="primary"
              class="text-none mt-5 mb-4"
              @click.prevent="select({ nome: selects, data: selectsTrue })"
              >Confirmar</v-btn
            >
          </template>
        </v-list>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import TheNavBar from "@/components/TheNavBar.vue";
import { filterArrayObejctByString } from "@/utils/filterArray";

export default {
  components: { TheNavBar },
  props: {
    searchByValue: {
      type: String,
      required: false,
    },
    headerTitle: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    labelKey: {
      type: String,
      required: false,
    },
    refKey: {
      type: String,
      required: false,
    },
    valueKey: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    selectMultiplos: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      dadosFiltrados: [],
      selects: {},
      selectsTrue: {},
      filtro: false,
    };
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        if (this.options.length > 0) {
          this.filtro = true;
        } else {
          this.filtro = false;
        }
        this.dadosFiltrados = [...this.options];
        if (this.searchByValue && this.dadosFiltrados.length > 0) {
          const filtrado = this.dadosFiltrados.filter((dado) => {
            const value = this.valueKey ? dado[this.valueKey].toString() : dado.toString();
            return value === this.searchByValue.toString();
          });
          this.$emit("select", filtrado[0]);
        }
      },
    },
    dialog(flag) {
      if (flag) this.$emit('open');
      else this.$emit('close');
    },
  },
  methods: {
    selectCheckBox(value) {
      if (value.target.checked) {
        this.selectsTrue[value.target.id] = value.target.getAttribute("data-refids") && value.target.getAttribute("data-refids").split(",");
      } else if (this.selectsTrue[value.target.id]) {
        delete this.selectsTrue[value.target.id];
      }
    },

    select(dado) {
      const value = this.valueKey ? dado[this.valueKey] : dado;
      if (
        this.selectMultiplos
        && Object.keys(dado.nome).length
        && Object.keys(dado.nome)
          .map((e) => dado.nome[e])
          .includes(true)
      ) {
        this.$emit("select", this.returnObject ? dado : value);
        this.dialog = false;
      } else if (!this.selectMultiplos) {
        this.$emit("select", this.returnObject ? dado : value);
        this.dialog = false;
      } else {
        this.$root.$snackBar.open({
          color: "error",
          message: "Selecione um item",
        });
      }
    },
    search(value) {
      this.dadosFiltrados = filterArrayObejctByString({
        string: value,
        array: this.options,
        keyObject: this.labelKey,
      });
    },
  },
};
</script>

<style></style>
